import React from 'react';

import './header.css';
import logo from './nametrans.svg';
import divider from './divider.svg';
import Divider from '../divider';
import About from '../about';

const Header = () => {
  return <>
    <div className="Header">
      <div className="Title">
        <img src={logo} className="img-responsive" alt="Caitlin Trussell Logo"/>
        <img  src={divider} className="img-responsive" alt=""/>
      </div>
    </div>
    <Divider />
    <About/>
    </>
};

export default Header;
