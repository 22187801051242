import React from 'react';
import self from './self.jpg';
import resume from './caitlin_trussell_resume.pdf';

import './about.css';

const About = () => (
  <div className="Main">
    <div className="About-img">
      <img src={self} className="img-responsive Selfie" alt="Caitlin standing underneath mayan ruins" />
    </div>
    <div className="About-text">
      <h2>Hi there!</h2>
      <h3>
        I'm Caitlin, a software engineer.
      </h3>
      <p>
        I live in San Diego, CA and sometimes find myself at the foot of ancient
        ruins. I make user interfaces for the web with React and friends. When I'm not writing code, I'm probably hanging out with my dog, trying to keep my plants alive or watching Star Trek NextGen for the 50th time. Thanks for visiting!
      </p>
      <br />
      <p>
        <a href="https://github.com/caitlintrussell" target="blank">
          <i aria-label="visit my github profile" className="glyph fab fa-github fa-lg" />
        </a>
        <a
          href="https://www.linkedin.com/in/caitlintrussell/"
          target="blank"
        >
          <i aria-label="visit my LinkedIn profile"  className="glyph fab fa-linkedin fa-lg" />
        </a>
        <a href="mailto:caitlintrussell.com" target="blank">
          <i aria-label="send me an email" className="glyph fas fa-envelope fa-lg"/>
        </a>
        <a href={resume}> </a>
      </p>
    </div>
  </div>
);

export default About;
