

import React from 'react';
import './divider.css';

const Divider = () => {
  return (
    <div className="Divider" />
  )
}

export default Divider;
